import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Comment } from "semantic-ui-react";
import { Markdown } from "../../../lib/custom-ui";
import CommentEdit from "./CommentEdit";
import CommentAdd from "./CommentAdd";
import styles from "./ItemComment.module.scss";

const ItemComment = React.memo(
  ({ data, createdAt, isPersisted, user, canEdit, onUpdate, onDelete }) => {
    const [t] = useTranslation();
    const [isReplying, setIsReplying] = useState(false);
    const commentEdit = useRef(null);

    const handleEditClick = useCallback(() => {
      commentEdit.current.open();
    }, []);

    const handleReplyClick = useCallback(() => {
      setIsReplying(!isReplying);
    }, [isReplying]);

    const handleReplySubmit = useCallback(
      (replyData) => {
        const userLocalStore = JSON.parse(localStorage.getItem("user"));
        const loggedInUser = userLocalStore
          ? { name: userLocalStore.name, avatarUrl: userLocalStore.avatarUrl }
          : null;

        const newReply = {
          text: replyData.text,
          id: Date.now().toString(),
          userId: userLocalStore.id,
          user: { name: loggedInUser ? loggedInUser.name : "Anonymous" },
          createdAt: new Date().toISOString(),
          avatarUrl: loggedInUser ? loggedInUser.avatarUrl : "",
        };

        // Aseguramos que las réplicas anteriores se mantengan
        const updatedData = {
          ...data,
          replies: newReply,
        };

        onUpdate(updatedData);
        setIsReplying(false);
        data = {};
      },
      [data, onUpdate]
    );


    useEffect(() => {
      console.log("data",data);
      
    }, [data]);

    return (
      <Comment>
        
          <div className={styles.commentContainer}>
            
            <div className={styles.avatar}>
              <img
                src={user.avatarUrl}
                alt="Avatar"
                className={styles.avatarImage}
              />
            </div>
            
            <div className={classNames(styles.content)}>
              
              <div className={styles.title}>
                <span className={styles.author}>{user.name}</span>
                <span className={styles.date}>
                  {createdAt.toLocaleDateString()}{" "}
                  {createdAt.toLocaleTimeString()}
                </span>
              </div>
              <CommentEdit
                ref={commentEdit}
                defaultData={data}
                onUpdate={onUpdate}
              >
                <>
                  <div className={styles.text}>
                    <Markdown linkTarget="_blank">{data.text}</Markdown>
                  </div>

                  {isReplying && (
                    <div className={styles.replyBox}>
                      <CommentAdd onCreate={handleReplySubmit} />
                    </div>
                  )}
                </>
              </CommentEdit>
            </div>
          </div>
       
        <Comment.Actions>
          <Comment.Action
            as="button"
            content={t("action.reply")}
            style={{
              color: "white",
              marginLeft: "50px",
              marginBoottom: "10px",
            }}
            onClick={handleReplyClick}
          />
        </Comment.Actions>

        {data.replies && data.replies.length > 0 && (
          <Comment.Group>
            {data.replies.map((reply) => (
              <Comment key={reply.id}>
                <div className={styles.commentContainer}>
                  <div className={styles.avatar}>
                    <img
                      src={reply.user.avatarUrl}
                      alt="Avatar"
                      className={styles.avatarImage}
                    />
                  </div>
                  <div className={classNames(styles.content, styles.reply)}>
                    <div className={styles.title}>
                      <span className={styles.author}>{reply.user.name}</span>
                      <span className={styles.date}>
                        {new Date(reply.createdAt).toLocaleDateString() +
                          " " +
                          new Date(reply.createdAt).toLocaleTimeString()}
                      </span>
                    </div>
                    <div className={styles.text}>
                      <Markdown linkTarget="_blank">
                        {reply.text || ""}
                      </Markdown>
                    </div>
                  </div>
                </div>
              </Comment>
            ))}
          </Comment.Group>
        )}

        <br />
      </Comment>
    );
  }
);

ItemComment.propTypes = {
  data: PropTypes.shape({
    text: PropTypes.string.isRequired,
    replies: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        user: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
        createdAt: PropTypes.string.isRequired,
        avatarUrl: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  createdAt: PropTypes.instanceOf(Date).isRequired,
  isPersisted: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string.isRequired,
  }).isRequired,
  canEdit: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ItemComment;
