import React from 'react';
import PropTypes from 'prop-types';

import Filters from './Filters';
import Memberships from '../Memberships';
import BoardMembershipPermissionsSelectStep from '../BoardMembershipPermissionsSelectStep';

import styles from './BoardActions.module.scss';



const BoardActions = React.memo(
  ({
    memberships,
    labels,
    filterUsers,
    filterLabels,
    filterText,
    allUsers,
    canEdit,
    canEditMemberships,
    onMembershipCreate,
    onMembershipUpdate,
    onMembershipDelete,
    onUserToFilterAdd,
    onUserFromFilterRemove,
    onLabelToFilterAdd,
    onLabelFromFilterRemove,
    onLabelCreate,
    onLabelUpdate,
    onLabelMove,
    onLabelDelete,
    onTextFilterUpdate,
  }) => {
    return (
      <div className={styles.wrapper}>
      <div className={styles.actions}>
        <div className={styles.action}>
        <Memberships
          items={memberships}
          allUsers={allUsers}
          permissionsSelectStep={BoardMembershipPermissionsSelectStep}
          canEdit={canEditMemberships}
          onCreate={onMembershipCreate}
          onUpdate={onMembershipUpdate}
          onDelete={onMembershipDelete}
        />
        </div>
        <div className={styles.action}>
        <Filters
          users={filterUsers}
          labels={filterLabels}
          filterText={filterText}
          allBoardMemberships={memberships}
          allLabels={labels}
          canEdit={canEdit}
          onUserAdd={onUserToFilterAdd}
          onUserRemove={onUserFromFilterRemove}
          onLabelAdd={onLabelToFilterAdd}
          onLabelRemove={onLabelFromFilterRemove}
          onLabelCreate={onLabelCreate}
          onLabelUpdate={onLabelUpdate}
          onLabelMove={onLabelMove}
          onLabelDelete={onLabelDelete}
          onTextFilterUpdate={onTextFilterUpdate}
        />
        </div>
      </div>
      </div>
    );
  },
);

BoardActions.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  memberships: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  filterUsers: PropTypes.array.isRequired,
  filterLabels: PropTypes.array.isRequired,
  filterText: PropTypes.string.isRequired,
  allUsers: PropTypes.array.isRequired,
  /* eslint-enable react/forbid-prop-types */
  canEdit: PropTypes.bool.isRequired,
  canEditMemberships: PropTypes.bool.isRequired,
  onMembershipCreate: PropTypes.func.isRequired,
  onMembershipUpdate: PropTypes.func.isRequired,
  onMembershipDelete: PropTypes.func.isRequired,
  onUserToFilterAdd: PropTypes.func.isRequired,
  onUserFromFilterRemove: PropTypes.func.isRequired,
  onLabelToFilterAdd: PropTypes.func.isRequired,
  onLabelFromFilterRemove: PropTypes.func.isRequired,
  onLabelCreate: PropTypes.func.isRequired,
  onLabelUpdate: PropTypes.func.isRequired,
  onLabelMove: PropTypes.func.isRequired,
  onLabelDelete: PropTypes.func.isRequired,
  onTextFilterUpdate: PropTypes.func.isRequired,
};

export default BoardActions;
