import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import { Button, Form, TextArea } from "semantic-ui-react";
import { useForm } from "../../../hooks";
import styles from "./CommentAdd.module.scss";

const DEFAULT_DATA = {
  text: "",
};

const CommentAdd = React.memo(({ onCreate, users }) => {
  const [t] = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const [data, handleFieldChange, setData] = useForm(DEFAULT_DATA);
  const textField = useRef(null);

  const submit = useCallback(() => {
    const cleanData = {
      ...data,
      text: data.text.trim(),
    };

    if (!cleanData.text) {
      textField.current.ref.current.select();
      return;
    }

    onCreate(cleanData);
    setData(DEFAULT_DATA);
  }, [onCreate, data, setData]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault(); // Prevent default form submission behavior to avoid page reload
      submit();
    },
    [submit]
  );

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <TextArea
          style={{ border: "1px solid #656565" }}
          ref={textField}
          as={TextareaAutosize}
          name="text"
          value={data.text}
          minRows={isOpened ? 2 : 1}
          spellCheck={false}
          className={styles.field}
          onChange={handleFieldChange}
        />
        <div className={styles.controls}>
          <br />
          <Button type="submit" disabled={!data.text.trim()}>
            {t("action.addComment")}
          </Button>
        </div>
      </Form>
      <br />
    </>
  );
});

CommentAdd.propTypes = {
  onCreate: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
};

export default CommentAdd;
