import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { closePopup } from "../../lib/popup";

import DroppableTypes from "../../constants/DroppableTypes";
import ListContainer from "../../containers/ListContainer";
import CardModalContainer from "../../containers/CardModalContainer";
import ListAdd from "./ListAdd";
import { ReactComponent as PlusMathIcon } from "../../assets/images/plus-math-icon.svg";
import { Button, Modal } from "semantic-ui-react";
import Gantt from "../Gantt/Gantt";

import styles from "./Board.module.scss";
// import { getCards } from "../../legacy/apiLegacy";

const parseDndId = (dndId) => dndId.split(":")[1];
const boardId = window.location.pathname.split("/")[2];

const Board = React.memo(
  ({
    listIds,
    isCardModalOpened,
    canEdit,
    onListCreate,
    onListMove,
    onCardMove,
  }) => {
    const [t] = useTranslation();
    const [isListAddOpened, setIsListAddOpened] = useState(false);
    const [data, setData] = useState([]);

    const wrapper = useRef(null);
    const prevPosition = useRef(null);

    const handleAddListClick = useCallback(() => {
      setIsListAddOpened(true);
    }, []);

    const handleAddListClose = useCallback(() => {
      setIsListAddOpened(false);
    }, []);

    const handleDragStart = useCallback(() => {
      closePopup();
    }, []);

    
    

    const handleDragEnd = useCallback(
      ({ draggableId, type, source, destination }) => {
        if (
          !destination ||
          (source.droppableId === destination.droppableId &&
            source.index === destination.index)
        ) {
          return;
        }

        const id = parseDndId(draggableId);

        switch (type) {
          case DroppableTypes.LIST:
            onListMove(id, destination.index);

            break;
          case DroppableTypes.CARD:
            onCardMove(
              id,
              parseDndId(destination.droppableId),
              destination.index
            );

            break;
          default:
        }
      },
      [onListMove, onCardMove]
    );

    const handleMouseDown = useCallback(
      (event) => {
        // If button is defined and not equal to 0 (left click)
        if (event.button) {
          return;
        }

        if (
          event.target !== wrapper.current &&
          !event.target.dataset.dragScroller
        ) {
          return;
        }

        prevPosition.current = event.clientX;
      },
      [wrapper]
    );

    const handleWindowMouseMove = useCallback(
      (event) => {
        if (!prevPosition.current) {
          return;
        }

        event.preventDefault();

        window.scrollBy({
          left: prevPosition.current - event.clientX,
        });

        prevPosition.current = event.clientX;
      },
      [prevPosition]
    );

    const handleWindowMouseUp = useCallback(() => {
      prevPosition.current = null;
    }, [prevPosition]);

    useEffect(() => {
      document.body.style.overflowX = "auto";

      return () => {
        document.body.style.overflowX = null;
      };
    }, []);

    // const handleReloadData = () => {
    //   var boardId = window.location.pathname.split("/")[2];
    //   getCards(boardId).then((cardsData) => {
    //     const ganttData = transformData(cardsData.data);
    //     setData(ganttData);
    //   });
    // }

      

    useEffect(() => {
      if (isListAddOpened) {
        window.scroll(document.body.scrollWidth, 0);
      }
    }, [listIds, isListAddOpened]);

    useEffect(() => {
      window.addEventListener("mouseup", handleWindowMouseUp);
      window.addEventListener("mousemove", handleWindowMouseMove);

      return () => {
        window.removeEventListener("mouseup", handleWindowMouseUp);
        window.removeEventListener("mousemove", handleWindowMouseMove);
      };
    }, [handleWindowMouseUp, handleWindowMouseMove]);


    //Random Dark color in gray scale for the cards
    function getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    
    }
    

   
    function transformData(cardsData) {
      return cardsData
      .filter((board) => board.cards.some((card) => card.due_date))
      .map((board) => ({
        name: board.nameList,
        tareas: board.cards
        .filter((card) => card.due_date)
        .map((card) => ({
          id: card.id,
          text: card.name,
          name: card.name,
          start_date: card.created_at.slice(0, 10),
          color: getRandomColor(),
          duration: calculateDuration(card.created_at, card.due_date),
          end_date: card.due_date,
        })),
      }));
    }
    

    function calculateDuration(startDate, endDate) {
      if (!endDate) return 1; // Default to 1 month if end date is not provided
  
      const start = new Date(startDate);
      const end = new Date(endDate);
  
      let duration = (end.getFullYear() - start.getFullYear()) * 12;
      duration += end.getMonth() - start.getMonth();
  
      return duration; // Add 1 to include the start month
  }
  

    function getColorByCompletion(isCompleted) {
      return isCompleted ? "#2ecaac" : "#ff6252";
    }

    useEffect(() => {
      // getCards(boardId).then((cardsData) => {
      //   console.clear();
      //   console.log("AAAAAAAAAAA");
      //  const ganttData = transformData(cardsData.data);
      //   setData(ganttData);
      //   console.log(cardsData.data);
      // });
    }, []);

    return (
      <>
      <div
        ref={wrapper}
        className={styles.wrapper}
        onMouseDown={handleMouseDown}
      >
        <div>
        <DragDropContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <Droppable
          droppableId="board"
          type={DroppableTypes.LIST}
          direction="horizontal"
          >
          {({ innerRef, droppableProps, placeholder }) => (
            <>
            <div
              {...droppableProps} // eslint-disable-line react/jsx-props-no-spreading
              data-drag-scroller
              ref={innerRef}
              className={styles.lists}
            >
              {listIds.map((listId, index) => (
              <ListContainer key={listId} id={listId} index={index}/>
              ))}
              {placeholder}
              {canEdit && (
              <div data-drag-scroller className={styles.list}>
                {isListAddOpened ? (
                <ListAdd
                  onCreate={onListCreate}
                  onClose={handleAddListClose}
                />
                ) : (
                <button
                  type="button"
                  className={styles.addListButton}
                  onClick={handleAddListClick}
                >
                  <PlusMathIcon
                  className={styles.addListButtonIcon}
                  />
                  <span className={styles.addListButtonText}>
                  {listIds.length > 0
                    ? t("action.addAnotherList")
                    : t("action.addList")}
                  </span>
                </button>
                )}
              </div>
              )}
            </div>
            {/* <Modal
              trigger={<Button className={styles.gantt} onClick={handleReloadData}>Gantt</Button>}
              style={{ justifyContent: "center", marginLeft: "40px" }}
              size="fullscreen"
            
              // content={<Gantt data={data} />}
            
            >
             
            </Modal> */}
            </>
          )}
          </Droppable>
        </DragDropContext>
        </div>
      </div>
      {isCardModalOpened && <CardModalContainer />}
      </>
    );
  }
);

Board.propTypes = {
  listIds: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  isCardModalOpened: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  onListCreate: PropTypes.func.isRequired,
  onListMove: PropTypes.func.isRequired,
  onCardMove: PropTypes.func.isRequired,
};

export default Board;
