import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu } from 'semantic-ui-react';
import { Popup } from '../../../lib/custom-ui';

import { useSteps } from '../../../hooks';
import DeleteStep from '../../DeleteStep';
import AddMemberForTask from './AddMemberForTasks'; // Importa el nuevo componente

import styles from './ActionsStep.module.scss';

const StepTypes = {
  DELETE: 'DELETE',
};

const ActionsStep = React.memo(({ onNameEdit, onDelete, onClose }) => {
  const [t] = useTranslation();
  const [step, openStep, handleBack] = useSteps();
  const addMemberRef = useRef();

  const handleEditNameClick = useCallback(() => {
    onNameEdit();
    onClose();
  }, [onNameEdit, onClose]);

  const handleDeleteClick = useCallback(() => {
    openStep(StepTypes.DELETE);
  }, [openStep]);

  const handleAddMemberClick = useCallback(() => {
    if (addMemberRef.current) {
      addMemberRef.current.open(); // Asegúrate de que este método exista y funcione
    }
  }, []);

  const handleCreate = useCallback(
    (user) => {
      onNameEdit((prevDescription) => {
        const newDescription = `${prevDescription ? `${prevDescription} - ` : ''}Responsable: ${user.name}`;
        return newDescription;
      });
      onClose();
    },
    [onNameEdit, onClose]
  );
  
  if (step && step.type === StepTypes.DELETE) {
    return (
      <DeleteStep
        title="common.deleteTask"
        content="common.areYouSureYouWantToDeleteThisTask"
        buttonContent="action.deleteTask"
        onConfirm={onDelete}
        onBack={handleBack}
      />
    );
  }

  return (
    <>
      <Popup.Header>
        {t('common.taskActions', {
          context: 'title',
        })}
      </Popup.Header>
      <Popup.Content>
        <Menu secondary vertical className={styles.menu}>
          {/* <Menu.Item className={styles.menuItem} onClick={handleEditNameClick}>
            {t('action.editDescription', {
              context: 'title',
            })}
          </Menu.Item> */}
          <Menu.Item className={styles.menuItem} onClick={handleDeleteClick}>
            {t('action.deleteTask', {
              context: 'title',
            })}
          </Menu.Item>
          
        </Menu>
      </Popup.Content>
      {/* <AddMemberForTask ref={addMemberRef} onCreate={handleCreate}>
        {t('action.addMemberToTask', {
          context: 'title',
        })}
      </AddMemberForTask> */}
    </>
  );
});

ActionsStep.propTypes = {
  onNameEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActionsStep;
