import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import { Button, Form, TextArea } from 'semantic-ui-react';
import { useForm } from '../../../hooks';
import styles from './CommentEdit.module.scss';

const CommentEdit = React.forwardRef(({ children, defaultData, onUpdate }, ref) => {
  const [t] = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const [data, handleFieldChange, setData] = useForm(null);
  const [isOpenedMention, setIsOpenedMention] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const textField = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // Estado para controlar envíos simultáneos

  const open = useCallback(() => {
    setIsOpened(true);
    setData({
      text: '',
      ...defaultData,
    });
  }, [defaultData, setData]);

  const close = useCallback(() => {
    setIsOpened(false);
    setData(null);
  }, [setData]);

  const submit = useCallback(() => {
    // Prevenir múltiples envíos simultáneos
    if (isSubmitting) return;

    setIsSubmitting(true); // Bloquear futuros envíos hasta que se complete el actual

    const cleanData = {
      ...data,
      text: data.text.trim(),
    };

    if (cleanData.text && cleanData.text !== defaultData.text) {
      onUpdate(cleanData);
    }

    setIsSubmitting(false); // Habilitar el envío nuevamente
    close();
  }, [data, onUpdate, defaultData, close, isSubmitting]);

  useImperativeHandle(
    ref,
    () => ({
      open,
      close,
    }),
    [open, close],
  );

  const handleFieldKeyDown = useCallback(
    (event) => {
      if (event.ctrlKey && event.key === 'Enter') {
        submit();
      }
    },
    [submit],
  );

  // Eliminamos el handleFieldBlur para evitar el envío al perder foco
  // const handleFieldBlur = useCallback(() => {
  //   submit();
  // }, [submit]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault(); // Prevenir el comportamiento predeterminado del formulario
      submit();
    },
    [submit],
  );

  useEffect(() => {
    if (isOpened) {
      textField.current.ref.current.focus();
    }
  }, [isOpened]);

  if (!isOpened) {
    return children;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <TextArea
        ref={textField}
        as={TextareaAutosize}
        name="text"
        value={data.text}
        minRows={3}
        spellCheck={false}
        className={styles.field}
        onKeyDown={handleFieldKeyDown}
      />
      {isOpenedMention && filteredUsers.length > 0 && (
        <select
          size={filteredUsers.length + 1}
          className={styles.selectFloat}
          onMouseDown={(event) => event.preventDefault()}
          value={selectedOption}
        >
          <option value="" disabled></option>
          {filteredUsers.map((user, index) => (
            <option key={index} value={user.username}>
              {user.name}
            </option>
          ))}
        </select>
      )}

      {isOpenedMention && filteredUsers.length === 0 && (
        <p style={{ color: 'white' }}>No se pueden mencionar usuarios porque no hay miembros en el tablero</p>
      )}
      <div className={styles.controls}>
        <Button positive content={t('action.save')} disabled={isSubmitting} />
      </div>
    </Form>
  );
});

CommentEdit.propTypes = {
  children: PropTypes.element.isRequired,
  defaultData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onUpdate: PropTypes.func.isRequired,
};

export default React.memo(CommentEdit);
